import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import TextField from '@mui/material/TextField';
import {InputLabel} from '@mui/material';
import styles from './SalesforceInfoForm.module.css';
import {Button} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {useDispatch} from 'react-redux';
import {addSalesforceOrg} from 'redux/actions';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloudQueueSharpIcon from '@mui/icons-material/CloudQueueSharp';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '370px',
    sm: '600px',
    md: '800px',
    lg: '800px',
  },
  maxHeight: '900px',
  // overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
};

const options = [
  'https://login.salesforce.com',
  'https://test.salesforce.com',
  'Salesforce Custom Login',
  'Salesforce Custom Login & Connected App',
];

function SalesforceInfoForm({closeModal}) {
  const [salesforceForm, setSalesforceForm] = useState({
    name: '',
    instanceurl: '',
  });

  const [customAppForm, setCustomAppForm] = useState({
    instanceurl: '',
    clientid: '',
  });

  const [open, setOpenCustomApp] = useState(false);
 
  const handleCloseCustomApp = () => {
    setOpenCustomApp(false);
    setSalesforceForm((cur) => ({
      ...cur,
      instanceurl: '',
    }));
    setCustomAppForm((cur) => ({
      ...cur,
      clientid: '',
      instanceurl: '',
    }));
  };

  const [showCustom, setShowCustom] = useState(false);
  const dispatch = useDispatch();

  const nameChangeHandler = (event) => {
    setSalesforceForm((cur) => {
      return {
        ...cur,
        [event.target.name]: event.target.value,
      };
    });
  };

  const customUrlHandler = (event) => {
    setCustomAppForm((cur) => {
      return {
        ...cur,
        [event.target.name]: event.target.value,
      };
    });
  };

  const clientIdHandler = (event) => {
    setCustomAppForm((cur) => {
      return {
        ...cur,
        [event.target.name]: event.target.value,
      };
    });
  };

  const urlChangeHandler = (event, value) => {
    if (value == options[2]) {
      setShowCustom(true);
      return;
    } else if (value != options[2] && showCustom == true) {
      setShowCustom(false);
    }
    if (value === options[3]) {
      setOpenCustomApp(true);
    } else {
      setOpenCustomApp(false);
    }
    setSalesforceForm((cur) => {
      return {
        ...cur,
        instanceurl: value,
      };
    });
  };

  const customLogin = (event) => {
    setSalesforceForm((cur) => {
      return {
        ...cur,
        instanceurl: event.target.value,
      };
    });
  };

  const [error, setError] = useState(false);
  const [errorCustom, setErrorCustom] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageCustom, setErrorMessageCustom] = useState('');

  const validUrlsCustom = useSelector(({salesforce}) => salesforce.validUrlsCustom);
  const validUrls = useSelector(({salesforce}) => salesforce.validUrls);

  const clickHandler = async (event) => {
    event.preventDefault();

    const instanceUrl = salesforceForm.instanceurl;
    const isValidUrl = validUrls.some(validurl => instanceUrl.endsWith(validurl)) ||(validUrlsCustom.some(validurl => instanceUrl.endsWith(validurl)) && !instanceUrl.includes('?'));

    if (isValidUrl) {
      dispatch(addSalesforceOrg(salesforceForm));
      setError(false);
      setErrorMessage('');
      setSalesforceForm(() => ({
        name: '',
        instanceurl: '',
      }));
      setCustomAppForm(() => ({
        clientid: '',
        instanceurl: '',
      }));
      setShowCustom(false);
    } else {
      setError(true);
      setErrorMessage('The instance URL should be in the format of *.my.salesforce.com');
    }
  };

  const customHandlerSubmit = async (event) => {
    event.preventDefault();
    const updatedCustomAppForm = {...customAppForm};
    updatedCustomAppForm.name = salesforceForm.name;

    const customInstanceUrl = updatedCustomAppForm.instanceurl;
    const isValidUrlCustom = validUrls.some(validurl => customInstanceUrl.endsWith(validurl)) ||(validUrlsCustom.some(validurl => customInstanceUrl.endsWith(validurl)) && !customInstanceUrl.includes('?'));

    if (isValidUrlCustom) {
      dispatch(addSalesforceOrg(updatedCustomAppForm));
      setOpenCustomApp(false);
      setErrorCustom(false);
      setErrorMessageCustom('');
      setSalesforceForm((cur) => ({
        ...cur,
        name: '',
        instanceurl: '',
      }));
      setCustomAppForm((cur) => ({
        ...cur,
        clientid: '',
        instanceurl: '',
      }));
    } else {
      setErrorCustom(true);
      setErrorMessageCustom('The instance URL should be in the format of *.my.salesforce.com');
    }
  };

  return (
    <>
      <Fragment>
        <form onSubmit={clickHandler}>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
              Workspace Name
            </InputLabel>
            <TextField
              id='filled-basic'
              label='Name'
              variant='filled'
              placeholder='Name to Remember your Salesforce'
              name='name'
              value={salesforceForm.name}
              onChange={nameChangeHandler}
              sx={{
                width: {xs: '100%', sm: '480px', md: '480px'},
              }}
              required
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
              Salesforce Login URL
            </InputLabel>

            <Autocomplete
              disablePortal
              id='combo-box-demo'
              options={options}
              onChange={urlChangeHandler}
              value={salesforceForm.instanceurl}
              sx={{
                width: {xs: '100%', sm: '480px', md: '480px'},
              }}
              required
              renderInput={(params) => (
                <TextField
                  //   className={styles.textField}
                  {...params}
                  variant='filled'
                  label='URL'
                  size='small'
                  name='url'
                  required
                />
              )}
            />
          </div>

          {showCustom && (
            <Fragment>
              <div className={styles.infoCustomContainer}>
                <p className={styles.infoCustomLogin}>
                  Please enter your Salesforce instance URL. The instance URL
                  should be in the format of *.my.salesforce.com, where * is
                  your sub domain. (Do not use *.lightning.force.com URL here)
                </p>
              </div>

              <div className={styles.inputContainer}>
                <InputLabel className={styles.labelInput}>
                  Custom Login
                </InputLabel>
                <TextField
                  sx={{
                    width: {xs: '100%', sm: '480px', md: '480px'},
                  }}
                  variant='filled'
                  label='URL'
                  size='small'
                  placeholder='https://sample.my.salesforce.com'
                  name='url'
                  onChange={customLogin}
                  value={salesforceForm.instanceurl}
                  required
                  type='url'
                  error={error}
                  helperText={error ? errorMessage : ''}
                />
              </div>
            </Fragment>
          )}

          <div
            style={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}
          >
            <Button variant='contained' color='secondary' onClick={closeModal}>
              Cancel
            </Button>
            <Button variant='contained' type='submit'>
              Save
            </Button>
          </div>
        </form>
      </Fragment>
      {/* Custom App Modal */}
      <Modal
        open={open}
        onClose={handleCloseCustomApp}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <form onSubmit={customHandlerSubmit}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <CloudQueueSharpIcon />
              <h4>Salesforce Custom Login & Connected App</h4>
            </div>
            <div className={styles.inputContainer}>
              <InputLabel className={styles.labelInput}>
                Custom URL *
              </InputLabel>
              <TextField
                id='filled-basic'
                label='Custom Url'
                variant='filled'
                name='instanceurl'
                value={customAppForm.instanceurl}
                error={errorCustom}
                helperText={errorCustom ? errorMessageCustom : ''}
                sx={{
                  width: {xs: '100%', sm: '480px', md: '480px'},
                }}
                onChange={customUrlHandler}
                required
              ></TextField>
            </div>
            <div className={styles.inputContainer}>
              <InputLabel className={styles.labelInput}>Client ID</InputLabel>
              <TextField
                id='filled-basic'
                label='Client Id'
                variant='filled'
                name='clientid'
                value={customAppForm.clientid}
                sx={{
                  width: {xs: '100%', sm: '480px', md: '480px'},
                }}
                onChange={clientIdHandler}
                required
              ></TextField>
            </div>
            <div className={styles.btnContainer}>
              <Button
                onClick={handleCloseCustomApp}
                variant='contained'
                color='secondary'
              >
                Close
              </Button>
              <Button type='submit' variant='contained'>
                Save
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
      {/* Custom App Modal */}
    </>
  );
}

export default SalesforceInfoForm;

SalesforceInfoForm.propTypes = {
  closeModal: PropTypes.func,
};
