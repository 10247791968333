import {
  GET_SFDC_INFO,
  GET_PAGE_COUNT,
  GET_CUR_PAGE,
  ADD_SALESFORCE_ORG_RESULT,
  ADDING_ORG_IN_PROCESS,
  EDIT_SALESFORCE_ORG_RESULT,
  SALESFORCE_RESPONSE,
  SFDC_ASSIGN_RESPONSE,
  GET_VALID_URLS,
  GET_VALID_URL,
} from 'shared/constants/ActionTypes';

const initialState = {
  sfdcInfo: null,
  pageCount: 0,
  curPage: 1,
  addSalesforceOrgResult: null,
  editSalesforceOrgResult: null,
  addingOrgInProcess: false,
  salesforceResponse: null,
  sfdcAssignResponse: null,
  validUrlsCustom: ['my.salesforce.com', 'my.sfcrmproducts.cn'],
  validUrls: ['https://login.salesforce.com', 'https://test.salesforce.com'],
};

const salesforceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SFDC_INFO:
      return {
        ...state,
        sfdcInfo: action.payload,
      };
    case GET_PAGE_COUNT:
      return {
        ...state,
        pageCount: action.payload,
      };
    case GET_CUR_PAGE:
      return {
        ...state,
        curPage: action.payload,
      };
    case ADD_SALESFORCE_ORG_RESULT:
      return {
        ...state,
        addSalesforceOrgResult: action.payload,
      };
    case ADDING_ORG_IN_PROCESS:
      return {
        ...state,
        addingOrgInProcess: action.payload,
      };
    case EDIT_SALESFORCE_ORG_RESULT:
      return {
        ...state,
        editSalesforceOrgResult: action.payload,
      };

    case SALESFORCE_RESPONSE:
      return {
        ...state,
        salesforceResponse: action.payload,
      };
    case SFDC_ASSIGN_RESPONSE:
      return {
        ...state,
        sfdcAssignResponse: action.payload,
      };
    case GET_VALID_URLS:
      return {
        ...state,
        validUrlsCustom: [...state.validUrlsCustom, action.payload],
      };
    case GET_VALID_URL:
      return {
        ...state,
        validUrls: [...state.validUrls, action.payload],
      };
    default:
      return state;
  }
};
export default salesforceReducer;
